import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import Phone, {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import es from "react-phone-number-input/locale/es";
import en from "react-phone-number-input/locale/en";
import pt from "react-phone-number-input/locale/pt";

const locale = { es, en, pt };

const Input = React.forwardRef((props, ref) => {
  const newProps = { ...props };
  delete newProps.focusInputOnCountrySelection;
  return (
    <input
      ref={ref}
      {...newProps}
      autoComplete="--disable"
      className={`form-control ${props.isinvalid && "is-invalid"}`}
      type="text"
      inputMode="numeric"
      pattern="[0-9+ \-]*"
    />
  );
});

const Component = React.forwardRef((props, ref) => {
  const inputRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [phone, setPhone] = useState({});

  const onChange = ({ target: { name, value, type } }) => {
    const payload = JSON.clone({ ...phone });
    payload[name] = value;
    payload.label = formatPhoneNumberIntl(payload.value || "");
    payload.isPossiblePhoneNumber = isPossiblePhoneNumber(payload.value || "");
    setPhone(payload);
    props.onChange(payload);
  };

  useImperativeHandle(ref, () => ({
    focus: () => {
      if(inputRef.current){
        inputRef.current.focus();
      }
    }
  }))

  let [language, country] = i18n.language.split("-");
  language = language?.toLowerCase() || "";

  country = phone.country || country || "ar";
  if ((country = "419")) {
    country = "ar";
  }
  const labels = locale[language] || locale.es;

  useEffect(() => {
    if (props.value && props.value.constructor === String) {
      setPhone({ value: props.value });
    } else if (props.value && props.value.constructor === Object) {
      setPhone({ ...props.value });
    }
  }, [props.value]);

  useEffect(() => {
    if (!phone.country && country && phone.value) {
      onChange({ target: { name: "country", value: country } });
    }
  }, [country, phone.country, phone.value, onChange]);

  return (
    <React.Fragment>
      <Phone
        international={
          props.international !== undefined ? props.international : true
        }
        labels={labels}
        id={props.id || "phone"}
        name={props.name || "phone"}
        required={props.required}
        disabled={!!props.disabled}
        defaultCountry={props.defaultCountry || country.toUpperCase()}
        inputComponent={props.Input || Input}
        focusInputOnCountrySelection={false}
        placeholder={
          props.placeholder ||
          t("phone_placeholder", { context: country.toLowerCase() })
        }
        value={phone.value || ""}
        onCountryChange={(value) =>
          onChange({ target: { name: "country", value } })
        }
        onChange={(value) => onChange({ target: { name: "value", value } })}
        isinvalid={props.isInvalid ? "true" : undefined}
        ref={inputRef}
      />

      { props.isInvalid && props.type === "account" ? (
          <div className="form-text text-danger">{t("phone_required" ,"Debés completar correctamente el número de teléfono para avanzar")}</div>
        ) : (
        props.hint !== false && (
          <div className="d-flex align-items-center justify-content-between">
          <div
          className="form-text"
          dangerouslySetInnerHTML={{
            __html: t("phone_hint", { context: country.toLowerCase() }),
          }}
          />
          {!props.disabled && props.onAdd && (
            <button
            type="button"
            className="btn btn-sm p-0 text-primary"
            onClick={props.onAdd}
            >
              {t("new")}
            </button>
          )}
          </div>
        )
      )}
    </React.Fragment>
  );
});

export default Component;
